const system = {
    namespaced: true,
    state: {
        title: '冀优通',
        timer: null
    },
    mutations: {
        setTimer (state, payload) {
            state.timer = payload;
        }
    },
    actions: {},
    getters: {
        getAppTitle: state => state.title,
        getTimer: state => state.timer
    }
};

export default {
    moduleName: 'system',
    module: system
};
