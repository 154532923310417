const dev = true;
const ipConfig = {
    dev,
    baseUrl: dev ? 'http://ozapi.zopsen.com/api/' : 'http://ozapi.zopsen.com/api/', // 测试
    baseUrlImg: 'http://ozapi.zopsen.com/', // 测试图片地址
    cc: 1
};
// const ipConfig = {
//     dev,
//     baseUrl: dev ? 'http://192.168.1.3:8088/api/' : 'http://192.168.1.3:8088/api/', // 测试
//     baseUrlImg: 'http://192.168.1.3:8088/api/', // 测试图片地址
//     cc: 1
// };

export default ipConfig;
