// 引入全局样式
import './global.scss'; //
import 'lib-flexible';
import axios from '@/api/axios';
import { Button, NavBar, RadioGroup, Panel, Empty, ContactList, Uploader, Tag, Divider, AddressList, Radio, Swipe, Popup, Stepper, Area, SubmitBar, SwipeItem, Cell, CellGroup, Checkbox, CheckboxGroup, Lazyload, List, Skeleton, Toast, Tabs, Tab, ImagePreview, Form, Overlay, Field, Tabbar, Collapse, CollapseItem, TabbarItem, Icon } from 'vant';
import router from './router';
import store from './store';
import { requestUrl } from './api/api';
import Vuelidate from 'vuelidate';
import { imgUrl } from '@/common/utils';
import * as filters from '@/common/filters';
import './common/directives';
import to from 'await-to-js';
import config from './common/config';
import ipConfig from './api/ip_config';
import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wx-open-launch-weapp'];
// 请求地址挂载
Vue.prototype.$requestUrl = requestUrl;
Vue.prototype.$axios = axios;
Vue.prototype.$toast = Toast;
Vue.prototype.$imgUrl = imgUrl; // 拼接阿里oss地址
Vue.prototype.$imagePreview = ImagePreview; // 图片预览
Vue.prototype.$to = to; // 图片预览
Vue.prototype.$config = config; // 全局配置
Vue.prototype.$ipConfig = ipConfig; // 全局配置

// 注册库
Vue.use(Vuelidate);
Vue.use(Radio);
Vue.use(AddressList);
Vue.use(Cell);
Vue.use(Panel);
Vue.use(Empty);
Vue.use(RadioGroup);
Vue.use(ContactList);
Vue.use(Divider);
Vue.use(Tag);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(Uploader);
Vue.use(Popup);
Vue.use(Area);
Vue.use(NavBar);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(CheckboxGroup);
Vue.use(SubmitBar);
Vue.use(Checkbox);
Vue.use(Stepper);
Vue.use(Lazyload);
Vue.use(Skeleton);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Overlay);
Vue.use(Tabbar);
Vue.use(List);
Vue.use(TabbarItem);
Vue.use(Icon);

// 全局过滤器
for (const arrayKey in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, arrayKey)) {
        Vue.filter(arrayKey, filters[arrayKey]);
    }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
