<template>
<div class="coupon-box">
     <div class="title">
         <div>核对码:</div>
         <div style="margin-left: 20px;">{{openid}}</div>
         </div>
    <ul>
        <li v-for="item in list" :key="item.id" @click="goCouponDetail(item)">
            <h3>{{item.deductionName}}</h3>
            <p style="padding: 0;">
                <span class="key">兑换码: </span>
                <span class="value">{{item.code}}</span>
            </p>
            <p>
                <span class="key">发放时间: </span>
                <span class="value">{{item.createtime}}</span>
            </p>
            <div class="status">
                <span :class="[item.state === 1 ? 'red-color' : '']">{{item.state === 1 ? '待领取' : '已领取'}}</span>
            </div>
        </li>
    </ul>
</div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { required } from 'vuelidate/lib/validators';
    import { isPoneAvailable } from '@/common/utils';
    import config from '@/common/config';
    import router from '../../router';
    function getQueryString (name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        console.log(name);
        console.log(window.location.search);
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    }
    export default {
        name: 'Login',
        data () {
            return {
                  list: [],
                  openid:""
            };
        },
        mounted () {
            // 如果想要登录页面提示 请在params里传入showToast
            if (this.$route.params.showToast) {
                this.$toast(this.$route.params.showToast);
            }

               const code = getQueryString('code');
            	//不存在code
                if(!code){
            		//获取code
                    this.wechatLogin ();
                    return;
                }
               if (code) {
                    this.$axios.get(this.$requestUrl.login.getUserOpenid, {
                        params: {
                            code
                        }
                    }).then(res => {
                         let openid = window.localStorage.getItem("openid");
                        if (res.code === '101' && openid==null) {
                            window.localStorage.setItem("openid",res.data);
                            openid = window.localStorage.getItem("openid");
                        }

                        // const openid="ofEnp6SUCBqMmc_9A0LOoMHkrCyY";
                         
                        this.openid = openid;
                        this.$axios.get(`${this.$requestUrl.login.getDeductionRecord}?openid=`+openid).then(result => {
                                 this.list = result.data;
                         });
                    }).catch(() => {
                        this.$toast('登录出错');
                    });
               }

    

        },
        methods: {
            ...mapActions(['getPoster']),
            onSubmit (data) {
                console.log(data);
            },
           goCouponDetail (item) {
               if(item.state!=1){
                   return;
               }
                if (window.confirm("确认领取立减金吗") == true) {
                    this.$toast.loading({
                        message: '加载中...',
                        forbidClick: true
                    });
                      const openid = window.localStorage.getItem("openid");
                      this.$axios.post(`${this.$requestUrl.login.userDrawCouponNew}?couponCode=${item.code}&openid=`+openid).then(res => {
                       if (res.code !== '101') {
                           this.$toast(res.message);
                           return;
                       }
                       if (res.code === '101') {
                           this.$toast.loading({
                               duration: 0, // 持续展示 toast
                               forbidClick: false,
                               message: '领取成功,请在消费时选择抵减，领取成功不一定进微信卡包'
                           });
                       }
                       this.$toast.clear();
                       this.$axios.get(`${this.$requestUrl.login.getDeductionRecord}?openid=`+openid).then(result => {
                                this.list = result.data;
                        });
                     });
                };

            },
         wechatLogin () {
               window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx79d3026485219429&redirect_uri=http://shousucoupon.zopsen.com&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
           }

        },
        // 表单验证
        validations: {
        },
        computed: {
        },
        destroyed () {
        }
    };
</script>

<style scoped lang="scss">
 .title{
     padding: 40px;
     background-color: #FFF;
     margin-top:20px;
     font-size: 20px;
     display: flex;
     flex-direction: row;
 }
.coupon-box{
    ul{
        padding: 40px;
        li{
            margin-bottom: 24px;
            padding: 20px  180px 20px 34px;
            background-color: white;
            border: 1px solid #d5d5d5;
            border-radius: 16px;
            position: relative;
            h3{
                margin-top: 2px;
                margin-bottom: 14px;
                font-size: 30px;
                //border-bottom: 1px solid #d2d2d2;
            }
            p{
                padding-top: 10px;
                .key{
                    font-size: 28px;
                    font-weight: bold;
                }
                .value{
                    color: gray;
                    font-size: 26px;
                }
            }
            .status{
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                width: 160px;
                border-left: 1px solid #bfbfbf;
                @include flexCenter;
            }
        }
    }
}
</style>
