import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFound from '@/pages/NotFound';
import store from '@/store';
import Login from '../pages/login/Login';
import config from '../common/config';
import CouponRecord from '../pages/login/CouponList';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: { titleName: '登录', showNav: false, showTabbar: false }
    },
     {
        path: '/coupon_record',
        name: 'CouponRecord',
        component: CouponRecord,
        meta: { titleName: '领取记录', showTabbar: false }
    },
    {
        path: '*',
        component: NotFound
    } // 全不匹配的情况下，返回404，路由按顺序从上到下，依次匹配。最后一个*能匹配全部，
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    store.commit('setTitleName', { titleName: to.meta.titleName || config.defaultTitle });
    store.commit('setShowNav', { showNav: (to.meta.showNav === undefined || to.meta.showNav === null) ? config.defaultShowNav : to.meta.showNav });
    store.commit('setlLeftNavType', { leftNavType: (to.meta.leftNavType === undefined || to.meta.leftNavType === null) ? config.defaultLeftNavType : to.meta.leftNavType });
    store.commit('setShowTabbar', { showTabbar: (to.meta.showTabbar === undefined || to.meta.showTabbar === null) ? config.defaultShowTabbar : to.meta.showTabbar });

    if (to.name !== 'Login' && !store.state.user.userInfo.token) {
        next({
            path: '/login'
        });
        return;
    } else if (to.name !== 'Login' && to.name !== 'WechatLogin' && !store.state.user.userInfo.openid && store.state.user.userInfo.token) {
        next({
            path: '/wechat_login'
        });
        return;
    }
    // if (to.matched.some(record => record.meta.requiresAuth)) {
    //   // this route requires auth, check if logged in
    //   // if not, redirect to login page.
    //   if (!auth.loggedIn()) {
    //     next({
    //       path: '/login',
    //       query: { redirect: to.fullPath }
    //     });
    //   } else {
    //     next();
    //   }
    // } else {
    //   next(); // 确保一定要调用 next()
    // }
    next(); // 确保一定要调用 next()
});

router.afterEach((to, from) => {});

export default router;
